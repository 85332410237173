import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Img from 'gatsby-image'
import { CalendarAlt } from 'styled-icons/fa-solid/CalendarAlt';
import Container from '../components/shared/Container'
import Layout from '../components/shared/Layout'
import SEO from '../components/shared/SEO'
import Pagination from '../components/shared/Pagination'
 
class ArticlesPage extends React.Component {

  render() {
    
    const { data } = this.props
    const { currentPage, numPages } = this.props.pageContext
    
    return (
      <Layout>

        <SEO title='Articles' />

        <Container>
          <PageTitle>Articles</PageTitle>
          <PageSubtitle>Updates about urbanlink.nl</PageSubtitle>

          <ItemsWrapper>
            <Items>
              {data.allMarkdownRemark.edges.map(({ node }, ...index) => (
                <Item key={index}>
                  <ItemInner to={node.frontmatter.path}>
                    { node.frontmatter.featured_img && (
                      <ItemImgWrapper>
                        <ItemImg fluid={ node.frontmatter.featured_img.childImageSharp.fluid } />
                      </ItemImgWrapper>
                    )}
                      
                    <ItemPreview>
                      <ItemMeta>
                        <DateWrapper>
                          <CalendarAltIcon /> { node.frontmatter.date }
                        </DateWrapper>
                        {node.frontmatter.sections && (
                          <SectionsWrapper>
                            { node.frontmatter.sections.map(section => (
                              <SectionItem>{section}</SectionItem>
                            ))}
                          </SectionsWrapper>
                        )}
                      </ItemMeta>
                      <ItemTitle>{ node.frontmatter.title }</ItemTitle>
                      <ItemExcerpt>{node.frontmatter.excerpt}</ItemExcerpt>
                    </ItemPreview>                    
                  </ItemInner>
                </Item>
              ))}
            </Items>
          </ItemsWrapper>

          <Pagination 
            basePath={'/artikelen/'} 
            currentPage={currentPage} 
            numPages={numPages} />

        </Container>
      </Layout>
    )
  }
}

const PageTitle = styled.h1`${tw`text-left`}`
const PageSubtitle = styled.h2`${tw`text-left`}`
const ItemsWrapper = styled.div`${tw``}`
const Items = styled.ul`${tw`flex flex-wrap list-none pl-0`}`
const Item = styled.li`${tw`w-full md:w-1/3 mb-4`};
  a:hover img {
    transform: translateZ(0) scale3d(1.04,1.04,1.04);
  }
`
const ItemInner = styled(Link)`${tw`text-black hover:no-underline block p-2 overflow-hidden`};
`
const ItemPreview = styled.div`${tw``}`
const ItemTitle = styled.h4`${tw`mt-2`}`
const ItemMeta = styled.div`${tw`flex`}`
const DateWrapper = styled.div`${tw`text-gray-500 text-sm`}`
const CalendarAltIcon = styled(CalendarAlt)`${tw`text-gray-500 text-xs w-3 mr-1`}`
const SectionsWrapper = styled.div`${tw`text-sm`}`
const SectionItem = styled.span`${tw`text-gray-500 px-2 py-1 bg-gray-300 rounded ml-1 text-gray-600`}`
const ItemExcerpt = styled.p`${tw`mt-1`}`
const ItemImgWrapper = styled.div`${tw`pr-2 overflow-hidden`}`
const ItemImg = styled(Img)`${tw`w-full mb-2`}; 
  transition: transform .5s ease;
  transform: translateZ(0) scaleX(1);
`

export default ArticlesPage

export const pageQuery = graphql`

  query articleListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}, 
      filter: {
        frontmatter: {
          type: {eq: "article"},
          draft: {ne: true }
        }
      },
      limit: $limit,
      skip: $skip 
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
          }
        }
      }
    }
  }`
