import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import tw from 'tailwind.macro';

class PaginationComponent extends React.Component {


  render() {
    const { basePath, numPages, currentPage } = this.props; 
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? basePath : basePath+(currentPage - 1).toString()
    const nextPage = basePath+(currentPage + 1).toString()

    if (numPages<2) { return null; }
    return (
      <Wrapper>
        
        {!isFirst && ( 
          <First to={prevPage} rel="prev"> ← Vorige pagina </First>
        )}
        
        {Array.from({ length: numPages }, (_, i) => (
          <NavItem key={`pagination-number${i + 1}`}>
            <NavItemLink 
              to={`${basePath}${i === 0 ? '' : i + 1}`}
              isCurrent={`${(i+1)===currentPage}`}
            >
              {i + 1}
            </NavItemLink>
          </NavItem>
        ))}

        {!isLast && (
          <Last to={nextPage} rel="next">Volgende Pagina →</Last>
        )}
      </Wrapper>
    )
  }
}

// PaginationComponent.propTypes = {
//   basePath: PropTypes.string.isRequired,
//   numPages: PropTypes.number.isRequired,
//   currentPage: PropTypes.number.isRequired
// }

const Wrapper = styled.ul`
  ${tw`flex flex-wrap pt-2 pl-0 list-none`};
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #efefef;
`
const First = styled(Link)`${tw`text-sm hover:underline`}`
const NavItem = styled.li`${tw`text-sm m-0`}`
const NavItemLink = styled(Link)`
  ${tw`inline-block rounded p-2 hover:underline`};
  ${props => props.isCurrent === "true" && `
    color: #FFF;
    background-color: #007acc;
  `}  
`
const Last = styled(Link)`${tw`text-sm hover:underline`}`

export default PaginationComponent; 